<script setup lang="tsx">
import { renderBoolean } from '~/entities/shared/render'

const userStore = useUserStore()
const config = useRuntimeConfig()
const { $i18n, $messageApi, $hasFlag } = useNuxtApp()

const actions = useDropdown([
  {
    type: 'render',
    render: () => (
      <div class="flex flex-col gap-0 p-2">
        <div class="font-semibold text-lg">
          {userStore.user?.firstName}
          {' '}
          {userStore.user?.lastName}
        </div>
        <div class="text-gray-500">{userStore.user?.email}</div>
      </div>
    )
  },
  { type: 'divider', condition: () => userStore.user?.status === 'COMPLETED' },
  {
    icon: 'mdi:account',
    label: () => $i18n.t('labels.profile'),
    action: () => navigateTo({ name: 'profile-information' }),
    condition: () => userStore.user?.status === 'COMPLETED'
  },
  {
    icon: 'ic:outline-settings',
    label: () => $i18n.t('actions.switchOrganisation'),
    action: () => userStore.switchOrganisation(),
    condition: () => userStore.user?.status === 'COMPLETED' && !$hasFlag('EP_AUCTELIA_UI')
  },
  { type: 'divider' },
  {
    label: () => $i18n.t('actions.logout'),
    action: () => navigateTo({ name: 'auth-logout' }),
    icon: 'mdi:logout'
  },
  { type: 'divider', condition: () => config.public.env !== 'prod' },
  {
    icon: 'mdi:security',
    label: () => (
      <div class="flex items-center justify-between">
        <div>Access control :</div>
        {renderBoolean(userStore.enableAccessControl, { mode: 'tag', trueLabel: 'ON', falseLabel: 'OFF' })}
      </div>
    ),
    action: () => {
      userStore.enableAccessControl = !userStore.enableAccessControl
      nextTick(() => $messageApi[userStore.enableAccessControl ? 'success' : 'error']('Access control : ' + (userStore.enableAccessControl ? 'ON' : 'OFF')))
    }
  }
])
</script>

<template>
  <NDropdown
    :options="actions"
    placement="bottom-end"
    trigger="click"
  >
    <NButton
      type="primary"
      size="large"
      icon-placement="right"
    >
      <Icon name="mdi:account" />
      <template #icon>
        <Icon name="mdi:chevron-down" />
      </template>
    </NButton>
  </NDropdown>
</template>
